new WOW().init();
new Picker(document.querySelector('.js-inline-picker'), {
	container: '.js-mini-picker-container',
	controls: true,
	inline: true,
	format: 'MMMM/DD - HH:mm',
	months: [
	    'Январь',
	    'Февраль',
	    'Март',
	    'Апрель',
	    'Май',
	    'Июнь',
	    'Июль',
	    'Август',
	    'Сентабрь',
	    'Октябрь',
	    'Ноябрь',
	    'Декабрь',
	],
	increment: {
		minute: 1,
	}
});
$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});

$(document).ready(function(){ 
	if ($('.letter_form').hasClass('error_input')) {
		$('#send_letter').modal('show');
		// console.log('сработало');
	} else {
		// console.log('не вышло');
	};	
	if ($('.order_form').hasClass('error_input')) {
		$('#order').modal('show');
		// console.log('сработало');
	} else {
		// console.log('не вышло');
	};
});